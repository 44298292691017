import { useChallenges } from "../../api/hooks/home"
import { Home } from "../../components"
import { Page } from "../../components/@shared/page"

export default () => {
	const { loading, current, past, prizes, partners } = useChallenges()

	return (
		<Page title='Spoonflower Design Challenges'>
			<Home
				loading={loading}
				current={current}
				past={past}
				prizes={prizes}
				partners={partners}
			/>
		</Page>
	)
}
